<template>
    <div v-if="is_null" class="is_null">
        <div>
            <img src="@/assets/img/stop_recruit.png" />
            <div class="text-algin:center">该职位已停招</div>
            <div class="button" @click="onClickLeft">查看其它工作机会</div>
        </div>
    </div>
    <div v-else class="details">
        <div class="left_go-1" @click="onClickLeft">
            <div class=" iconfont icon-fanhui1"></div>
            <div>返回</div>
        </div>
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item v-for="(item, index) in details.factory_img_list" :key="index"><img :src="item" @click="lookImg(index)" /></van-swipe-item>
        </van-swipe>
        <div class="info">
            <div class="factory">
                <div class="name">
                    {{
            details.factory_short_name
              ? details.factory_short_name
              : details.factory_name
          }}
                </div>
                <div :class="['tips', 'color' + details.recruitment_status]">
                    {{ details.recruitment_status_desc }}
                </div>
            </div>
            <div class="wage">
                <div v-if="details.wage_for_display">
                    {{ details.wage_for_display }}
                </div>
                <div v-if="details.salary_for_display">
                    {{ details.salary_for_display }}
                </div>
            </div>
            <div class="tags" v-if="details.tag_name_list && details.tag_name_list.length">
                <div class="tag-small select" v-for="item in details.tag_name_list" :key="item.index">
                    {{ item }}
                </div>
            </div>
            <div class="address" v-if="details.recruitment_geo_address" @click="clickAddress('details')">
                <div><span class="iconfont icon-dingwei1"></span>{{ details.recruitment_geo_address }}</div>
            </div>
        </div>
        <div class="border"></div>
        <div class="member_info" v-if="info.mobile || info.wechat_qrcode">
            <div class="info_left">
                <img :src="info.wechat_avatar ? info.wechat_avatar : require('@/assets/img/avatar.png')" alt="" />
                <div style="text-align: left">
                    <div class="team_name">{{ info.operator_name }}</div>
                    <div>招聘经纪人</div>
                </div>
            </div>
            <div class="info_right">
                <a :href="`tel:${info.mobile}`" style="color: #333" v-if="info.mobile">
                    <div style="margin-right: 0.3rem">
                        <img src="@/assets/img/tel.png" alt="" />
                        <p>电话咨询</p>
                    </div>
                </a>
                <div v-if="info.wechat_qrcode" @click="goAddWechatCode">
                    <img src="@/assets/img/wx.png" alt="" />
                    <p>添加微信</p>
                </div>
            </div>
        </div>
        <div class="border"></div>
        <div class="block">
            <div class="title">薪资福利</div>
            <div class="content">
                <div v-if="details.wage_for_display">
                    工价：{{ details.wage_for_display }}
                </div>
                <div v-if="details.wage_desc">工价说明：{{ details.wage_desc }}</div>
                <div v-if="details.salary_for_display">
                    综合薪资：{{ details.salary_for_display }}
                </div>
                <div v-if="details.wage_period_desc">
                    发薪周期：{{ details.wage_period_desc }}
                </div>
                <div v-if="details.payday">
                    发薪日期：{{
            details.wage_period == "week"
              ? "周" + weekList[details.payday - 1]
              : details.payday + "号"
          }}
                </div>
                <div v-if="details.welfare" :class="['pre',fulixianx_expande ? 'content_zhize' : 'content_zhize_more' ]" ref="fulixianxcontfa">
                    <div>福利信息：</div>
                    <pre ref="fulixianxcont">{{details.welfare}}</pre>
                </div>
                <div v-if="fulixianx_is_more" @click="fulixianx_expande = !fulixianx_expande" class="click_more">
                    {{fulixianx_expande ? '查看更多' : '收起'}}
                </div>

                <!-- <div v-if="details.welfare">
                    <div class="content_zhize" ref="fulixianxcontfa" v-if="fulixianx_expande">
                        <div class="content_zhize_on" ref="fulixianxcont">
                            福利信息：{{ details.welfare }}
                        </div>
                        <div class="content_zhize_abs" v-if="fulixianx_is_more" @click="fulixianx_expande = false">
                            <div class="ceshiwenzishouqi_abs_rt">
                                查看更多
                            </div>
                        </div>
                    </div>
                    <div class="content_zhize_more" v-else>
                        <span>福利信息：{{ details.welfare }}</span>
                        <div class="content_zhize_more_abs" @click="fulixianx_expande = true">
                            收起
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="border"></div>
        <div class="block">
            <div class="title">岗位介绍</div>
            <div class="content">
                <div>岗位工种：{{ details.recruitment_job_name }}</div>
                <div v-if="details.job_content" :class="['pre',zhize_expande ? 'content_zhize' : 'content_zhize_more' ]" ref="zhizecontfa">
                    <div>岗位职责：</div>
                    <pre ref="zhizecont">{{details.job_content}}</pre>
                </div>
                <div v-if="zhize_is_more" @click="zhize_expande = !zhize_expande" class="click_more">
                    {{zhize_expande ? '查看更多' : '收起'}}
                </div>
            </div>
        </div>
        <div class="border"></div>
        <div class="block">
            <div class="title">岗位要求</div>
            <div class="content">
                <div v-if="details.min_age">
                    年龄：{{ details.min_age + " - " + details.max_age }}
                </div>
                <div v-if="details.sex_desc">性别：{{ details.sex_desc }}</div>
                <!-- <div class="content_zhize" ref="othercontfa" v-if="details.note && other_expande">
                    <div class="content_zhize_on" ref="othercont">
                        其他要求：{{ details.note }}
                    </div>
                    <div class="content_zhize_abs" v-if="other_is_more" @click="other_expande = false">
                        <div class="ceshiwenzishouqi_abs_lt">...</div>
                        <div class="ceshiwenzishouqi_abs_rt">
                            查看更多
                        </div>
                    </div>
                </div>
                <div class="content_zhize_more" v-else-if="details.note">
                    <span>其他要求：{{ details.note }}</span>
                    <div class="content_zhize_more_abs" @click="other_expande = true">
                        收起
                    </div>
                </div> -->
                <div v-if="details.note" :class="['pre',other_expande ? 'content_zhize' : 'content_zhize_more' ]" ref="othercontfa">
                    <div>其他要求：</div>
                    <pre ref="othercont">{{details.note}}</pre>
                </div>
                <div v-if="other_is_more" @click="other_expande = !other_expande" class="click_more">
                    {{other_expande ? '查看更多' : '收起'}}
                </div>

            </div>
        </div>
        <div class="border"></div>
        <div class="block" v-if="details.factory_intro">
            <div class="title">企业简介</div>
            <div class="content">
                <div v-if="details.factory_intro" :class="['pre',jianjie_expande ? 'content_zhize' : 'content_zhize_more' ]" ref="jianjiecontfa">

                    <pre style="    width: 100%;" ref="jianjiecont">{{details.factory_intro}}</pre>
                </div>
                <div v-if="jianjie_is_more" @click="jianjie_expande = !jianjie_expande" class="click_more">
                    {{jianjie_expande ? '查看更多' : '收起'}}
                </div>

                <!-- <div class="content_zhize" ref="jianjiecontfa" v-if="jianjie_expande">
                    <div class="content_zhize_on" ref="jianjiecont">
                        {{ details.factory_intro }}
                    </div>
                    <div class="content_zhize_abs" v-if="jianjie_is_more" @click="jianjie_expande = false">
                        <div class="ceshiwenzishouqi_abs_lt">...</div>
                        <div class="ceshiwenzishouqi_abs_rt">
                            查看更多
                        </div>
                    </div>
                </div>
                <div class="content_zhize_more" v-else>
                    <span>{{ details.factory_intro }}</span>
                    <div class="content_zhize_more_abs" @click="jianjie_expande = true">
                        收起
                    </div>
                </div> -->
            </div>
            <!-- <pre class="content">
                {{details.factory_intro}}
            </pre> -->
        </div>
        <div class="border" v-if="details.factory_intro"></div>
        <div class="block" v-if="info.stores && info.stores.length">
            <div class="title">招聘门店</div>
            <div class="content">
                <div v-for="(item, index) in info.stores" :key="index" class="store" @click="clickAddress(item)">
                    <div>
                        <div class="store_name">{{ item.store_name }}</div>
                        <div v-if="item.distance" class="store_distance">
                            {{ item.distance_desc }}
                        </div>
                    </div>
                    <div class="store_address" v-if="item.geo_address">
                        <span class="iconfont icon-dingwei1"></span>{{ item.geo_address }}
                    </div>
                    <div class="store_address" v-else>
                        本门店暂未添加地址
                    </div>
                </div>
            </div>
        </div>
        <div class="border" v-if="info.stores && info.stores.length"></div>
        <!-- 底部 -->
        <div class="footer-bottom" style="background-color: #f8f8f8;"></div>
        <div class="footer">
            <div :class="details.is_favorites ? 'icon_select' : ''">
                <span :class="[
            'iconfont',
            details.is_favorites ? 'icon-yishoucang' : 'icon-shoucang',
          ]" @click="favorites()"></span>
                <div>{{ details.is_favorites ? "已收藏" : "收藏" }}</div>
            </div>
            <!-- <div @click="show_share_content = true"> -->
            <div @click="shareH5" style="color:#0abbb9">
                <span class="iconfont icon-fenxiang3"></span>
                <div>分享</div>
            </div>

            <div class="button" @click="goRecruitmentApply" :class="is_click || is_apply ? 'd8' : ''">
                {{ is_apply ? "已报名" : "立即报名" }}
            </div>
        </div>
        <!-- 分享的内容 -->

        <div v-if="show_share" @click="show_share = false">
            <div class="mask" style="z-index: 4"></div>
            <div class="share_click">
                <img src="@/assets/img/share_click.png" alt="" />
            </div>
        </div>
        <div class="mask" v-if="show_share_content" @click="show_share_content = false"></div>
        <div class="share_content" v-if="show_share_content">
            <div class="share_top">分享</div>
            <div class="share_middle">
                <div @click="goImages">
                    <img src="@/assets/img/job1.png" alt="" />
                    <div>招工图</div>
                </div>
                <div @click="shareH5">
                    <img src="@/assets/img/job2.png" alt="" />
                    <div>分享给好友</div>
                </div>
            </div>
            <div class="share_bottom" @click="show_share_content = false">取消</div>
        </div>
    </div>
</template>
<script>
import "@/assets/css/index.css";
import config from "@/utils/config.js";
import { ImagePreview, Toast } from "vant";
import wx from "weixin-js-sdk";
export default {
    data() {
        return {
            src: `${config.ossImageUrl}`,
            details: {},
            job_id: 0,
            is_apply: false, // 还没报名
            zhize_is_more: false, //职责是否有跟多
            zhize_expande: true, //是收起还是展开
            jianjie_is_more: false, //企业简介是否有更多
            jianjie_expande: true, //是收起还是展开
            fulixianx_is_more: false, //福利信息是否有更多
            fulixianx_expande: true, //是收起还是展开
            other_is_more: false, //其它要求是否有更多
            other_expande: true, //是收起还是展开
            info: {}, // 业务员信息
            is_null: false,
            weekList: ["一", "二", "三", "四", "五", "六", "日"],
            hide_welfare: true, // 溢出隐藏福利信息
            hide_job_content: true, // 溢出隐藏工作内容
            hide_note: true, // 溢出隐藏其他要求
            hide_factory_intro: true, // 溢出隐藏企业简介
            show_share: false, // 是否展示分享给好友的遮罩层
            show_share_content: false, // 是否展示分享的遮罩层
            is_click: false,
            go_from: '',
        };
    },
    // 
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (from.path == '/login') { // 从登录页面进来的
                vm.go_from = "recruitmentList" // 跳转到列表页面
            }
        });
    },
    mounted() {
        this.$store.commit("hideTabbar");
        this.job_id = this.$route.query.rid
        if (this.$route.query.member_id || this.$route.query.m_id) {
            localStorage.setItem(
                "member_id",
                this.$route.query.member_id || this.$route.query.m_id
            );
            if (this.$route.query.src) {
                sessionStorage.setItem(
                    "src",
                    this.$route.query.src ? this.$route.query.src : -1
                ); // 海报码
                sessionStorage.setItem("source", 0); // 海报码
            }

            sessionStorage.setItem(
                "introducer_id",
                this.$route.query.introducer_id ||
                this.$route.query.int_id ||
                sessionStorage.getItem("introducer_id") ||
                -1
            ); // 推荐码
        }
        if (this.$route.query.source == '5' || this.$route.query.team_id) { // 从公众号进来的，或者有team_id
            sessionStorage.setItem('is_getAllList', 1)
            localStorage.setItem('team_id', this.$route.query.team_id || localStorage.getItem('team_id'))
        }
        this.Request(
            "job/enable_apply_check",
            "get",
            { job_id: this.job_id, member_id: localStorage.getItem('member_id') },
            true,
            false
        ).then((res) => {
            if (res.status == 0) {
                if (res.data.apply_enable) {
                    this.is_click = false;
                    this.is_apply = false;
                } else {
                    this.is_click = true;
                    this.is_apply = true;
                }
            }
        });
        this.getJobMemberInfo();
        this.getDetails();
    },
    methods: {
        getQuery(variable) {
            var query = unescape(window.location.href);
            var lengths = query.split("?");
            var vars = lengths[lengths.length - 1].split("&");
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split("=");
                if (pair[0] == variable) {
                    return pair[1];
                }
            }
            return false;
        },
        // 获取门店列表
        getJobMemberInfo() {
            var data = {
                job_id: this.job_id,
                member_id:
                    this.getQuery("member_id") || this.getQuery("m_id") || localStorage.getItem("member_id"),
            };
            if (this.$store.state.longitude) {
                data.longitude = this.$store.state.longitude;
                data.latitude = this.$store.state.latitude;
            }
            data.is_cache = sessionStorage.getItem('is_getAllList') || ''
            this.Request("client/user/job_member", "get", data, true, false).then(
                (res) => {
                    if (res.status == 0) {
                        if (this.getQuery('member_id') || this.getQuery('m_id')) {
                            this.$store.state.memberInfo = res.data
                            localStorage.setItem("team_id", res.data.team_id);
                        }
                        this.info = res.data;
                    }
                }
            );
        },
        // 收藏/取消收藏
        favorites() {
            var data = {
                recruitment_id: this.job_id,
                team_id: localStorage.getItem("team_id") || 0,
            };
            var method = "post";
            if (this.details.is_favorites) {
                method = "delete";
            }
            this.Request(
                "client/recruitment/favorites",
                method,
                data,
                true,
                false
            ).then((res) => {
                if (res.status == 0) {
                    this.details.is_favorites = this.details.is_favorites == 1 ? 0 : 1;
                    if (!this.details.is_favorites) {
                        this.$toast({
                            duration: 1000,
                            message: "取消收藏成功",
                        });
                    } else {
                        this.$toast({
                            duration: 1000,
                            message: "收藏成功",
                        });
                    }
                }
            });
        },
        getWxSDK() {
            var url = window.location.href.split("#")[0]; //动态获取链接
            var data = {
                url,
                mtype: "worker_agent_team_mp",
            };
            this.Request(
                "client/api/wechat/jssdk/jsapi_ticket",
                "get",
                data,
                true,
                false
            ).then((res) => {
                res = res.data;
                wx.config({
                    debug: false, // true:是调试模式,调试时候弹窗,会打印出日志
                    appId: res.appId, // 微信appid
                    timestamp: res.timestamp, // 时间戳
                    nonceStr: res.nonceStr, // 随机字符串
                    signature: res.signature, // 签名
                    jsApiList: [
                        "checkJsApi",
                        "updateAppMessageShareData",
                        "updateTimelineShareData",
                        "onMenuShareTimeline",
                        "onMenuShareAppMessage",
                        "onMenuShareQQ",
                        "onMenuShareWeibo",
                        "onMenuShareQZone",
                        "openLocation",
                    ],
                });
                wx.checkJsApi({
                    jsApiList: [
                        "checkJsApi",
                        "updateAppMessageShareData",
                        "updateTimelineShareData",
                        "onMenuShareTimeline",
                        "onMenuShareAppMessage",
                        "onMenuShareQQ",
                        "onMenuShareWeibo",
                        "onMenuShareQZone",
                        "openLocation",
                    ], // 需要检测的JS接口列表，所有JS接口列表见附录2,
                    success: function () {
                        //在这里提示点击右上角分享
                    },
                });
                if (this.isWeiXin()) {
                    wx.ready(() => {
                        var link;
                        if (sessionStorage.getItem("introducer_id")) {
                            link =
                                process.env.VUE_APP_BASE_URL +
                                `recruitmentDetails?member_id=${this.info.member_id}&rid=${this.job_id}&introducer_id=` +
                                sessionStorage.getItem("introducer_id");
                        } else if (this.info.jobseeker_id) {
                            link =
                                process.env.VUE_APP_BASE_URL +
                                `recruitmentDetails?member_id=${this.info.member_id}&rid=${this.job_id}&jobseeker_id=${this.info.jobseeker_id}&source=` +
                                sessionStorage.getItem("source");
                        } else {
                            link =
                                process.env.VUE_APP_BASE_URL +
                                `recruitmentDetails?member_id=${this.info.member_id}&team_id=${this.info.team_id}&rid=${this.job_id}&source=` +
                                sessionStorage.getItem("source");
                        }
                        var wxData = {
                            //执行分享
                            title: this.details.factory_short_name
                                ? this.details.factory_short_name
                                : this.details.factory_name, // 分享标题
                            desc: "这里有个好厂在招人，快来看看吧。", // 分享描述
                            link, // 分享链接
                            imgUrl: this.details.factory_img_list[0], // 分享图标
                            type: "link", // 分享类型,music、video或link，不填默认为link
                            success: function () {
                            },
                            cancel: function () { },
                        };
                        wx.updateAppMessageShareData(wxData); // 微信好友
                        wx.updateTimelineShareData(wxData); // 朋友圈
                        wx.onMenuShareTimeline(wxData); // 朋友圈（旧）
                        wx.onMenuShareAppMessage(wxData); // 微信好友（旧）
                        wx.onMenuShareQQ(wxData); // QQ
                        wx.onMenuShareWeibo(wxData); // 腾讯微博
                        wx.onMenuShareQZone(wxData); // QQ空间
                    });
                }
            });
        },
        // 获取定位
        clickAddress(value) {
            if (value == "details") {
                // 详情里的地址，要兼容以前老数据，以前有些地址是没有定位的
                var data = this.details;
                if (data.recruitment_geo_latitude) {
                    wx.openLocation({
                        latitude: data.recruitment_geo_latitude, // 纬度，浮点数，范围为90 ~ -90
                        longitude: data.recruitment_geo_longitude, // 经度，浮点数，范围为180 ~ -180。
                        name: data.recruitment_geo_address, // 位置名
                        address: data.recruitment_geo_address, // 地址详情说明
                        scale: 10, // 地图缩放级别,整形值,范围从1~28。默认为最大
                    });
                }
            } else {
                wx.openLocation({
                    latitude: value.geo_latitude, // 纬度，浮点数，范围为90 ~ -90
                    longitude: value.geo_longitude, // 经度，浮点数，范围为180 ~ -180。
                    name: value.store_name, // 位置名
                    address: value.geo_address, // 地址详情说明
                    scale: 10, // 地图缩放级别,整形值,范围从1~28。默认为最大
                });
            }
        },
        isWeiXin() {
            //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
            var ua = window.navigator.userAgent.toLowerCase();
            //通过正则表达式匹配ua中是否含有MicroMessenger字符串
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                return true;
            } else {
                return false;
            }
        },
        // 添加微信
        goAddWechatCode() {
            this.$router.push({ path: '/addWechatCode', query: { img: this.info.wechat_qrcode || '', name: this.info.operator_name || '', phone: this.info.mobile || '' } });
        },
        // 点击显示全部，或者隐藏
        clickHideShow(type) {
            this["hide_" + type] = !this["hide_" + type];
        },
        // 查看全部图片
        lookImg(key) {
            ImagePreview({
                images: this.details.factory_img_list,
                startPosition: key,
            });
        },
        // 返回上一页
        onClickLeft() {
            // 能在url里获取到member_id就说明没有上一页
            if (this.$route.query.member_id || this.go_from == '/recruitmentList') {
                this.$router.replace({ path: "/recruitmentList", query: { api: 1 } });
            } else {
                this.$router.go(-1);
            }
        },
        shareH5() {
            this.show_share = true;
        },
        // 跳转到立即报名页面
        goRecruitmentApply() {
            if (this.is_click) return;
            this.is_click = true
            this.Request("common/mobile_bind_status", "get", {}, true, false).then(
                (res) => {
                    if (res.status == 0) {
                        // 需要先判断是否有手机号码
                        if (res.data.bind_status) {
                            var data = {
                                job_id: this.job_id,
                                member_id: this.info.member_id || this.getQuery("member_id") || this.getQuery("m_id") || localStorage.getItem("member_id"),
                                source: sessionStorage.getItem("source"), // 有推荐id就是推荐码，没有就是报名码,
                                introducer_id:
                                    parseInt(sessionStorage.getItem("introducer_id")) > 0
                                        ? parseInt(sessionStorage.getItem("introducer_id"))
                                        : parseInt(localStorage.getItem("jobseeker_id")) || 0, // 推荐人id,
                            };
                            if (this.$store.state.longitude) {
                                data.geo_longitude = this.$store.state.longitude;
                                data.geo_latitude = this.$store.state.latitude;
                            }
                            this.Request(
                                "jobseeker/apply/v2",
                                "post",
                                data,
                                {},
                                true,
                                false
                            ).then((res) => {
                                if (res.status == 0) {
                                    this.is_apply = true;
                                    this.is_click = true // 报名成功不能点击
                                    Toast.success("报名成功");
                                    this.getJobMemberInfo();
                                } else {
                                    this.is_click = false
                                }
                            });
                        } else {
                            this.is_click = false
                            this.$router.push({
                                path: "/phoneNum",
                                query: { rid: this.job_id },
                            });
                        }
                    } else {
                        this.is_click = false
                    }
                }
            );
        },
        // 跳转到招工图页面
        goImages() {
            this.$router.push({
                name: "JobImages",
                params: {
                    details: JSON.stringify(this.details),
                    info: JSON.stringify(this.info),
                },
            });
        },
        getDetails() {
            var data = {
                member_id: this.getQuery("member_id") || this.getQuery("m_id") || localStorage.getItem("member_id"),
                job_id: this.job_id,
            };
            this.Request("client/jobseeker/apply/job", "get", data)
                .then((res) => {
                    if (res.status == 0) {
                        // 溢出隐藏福利信息
                        if (res.data.welfare && res.data.welfare.length > 70) {
                            this.hide_welfare = true;
                        } else {
                            this.hide_welfare = false;
                        }
                        // 溢出隐藏工作内容
                        if (res.data.job_content && res.data.job_content.length > 70) {
                            this.hide_job_content = true;
                        } else {
                            this.hide_job_content = false;
                        }
                        // 溢出隐藏其他要求
                        if (res.data.note && res.data.note.length > 70) {
                            this.hide_note = true;
                        } else {
                            this.hide_note = false;
                        }
                        // 溢出隐藏企业简介
                        if (res.data.factory_intro && res.data.factory_intro.length > 70) {
                            this.hide_factory_intro = true;
                        } else {
                            this.hide_factory_intro = false;
                        }
                        if (res.data.factory_img_list.length == 0) {
                            res.data.factory_img_list = [this.src + "job_details.png"];
                        }
                        this.is_null = false;
                        this.details = res.data;
                        sessionStorage.setItem(
                            "factory_name",
                            this.details.factory_short_name
                                ? this.details.factory_short_name
                                : this.details.factory_name
                        );
                        sessionStorage.setItem("job_name", this.details.recruitment_job_name);
                        this.details = { ...this.details };
                        this.$nextTick(() => {
                            if (
                                this.details &&
                                this.details.job_content &&
                                this.$refs.zhizecont.offsetHeight >
                                this.$refs.zhizecontfa.offsetHeight
                            ) {
                                this.zhize_is_more = true;
                            }
                            if (
                                this.details &&
                                this.details.note &&
                                this.$refs.othercont.offsetHeight >
                                this.$refs.othercontfa.offsetHeight
                            ) {
                                this.other_is_more = true;
                            }
                            if (
                                this.details &&
                                this.details.welfare &&
                                this.$refs.fulixianxcont.offsetHeight >
                                this.$refs.fulixianxcontfa.offsetHeight
                            ) {
                                this.fulixianx_is_more = true;
                            }
                            if (
                                this.details &&
                                this.details.factory_intro &&
                                this.$refs.jianjiecont.offsetHeight >
                                this.$refs.jianjiecontfa.offsetHeight
                            ) {
                                this.jianjie_is_more = true;
                            }
                        });
                        this.getWxSDK(); // jssdk
                    } else {
                        this.is_null = true;
                    }
                })
                .catch((err) => {
                    this.$toast({
                        duration: 2000,
                        message: `${err.msg}`,
                    });
                });
        },
    },
};
</script>

<style scoped>
.content_zhize_more {
    width: 100%;
    min-height: 130px;
    /* line-height: 48px; */
    color: #666;
    font-size: 26px;
    text-align: left;
    position: relative;
}
.content_zhize_more_abs {
    /* position: absolute; */
    /* bottom: 0;
    right: 0; */
    /* width: 90px; */
    height: 50px;
    line-height: 55px;
    text-align: center;
    font-size: 26px;
    color: #0abbb9;
}
.content_zhize {
    width: 100%;
    max-height: 138px;
    overflow: hidden;
    /* letter-spacing: 0; */
    /* text-align: justify; */
    position: relative;
}
.content_zhize_on {
    width: 100%;
    /* line-height: 48px; */
    color: #666;
    font-size: 26px;
}
.content_zhize_abs {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 170px;
    height: 50px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    z-index: 1;
}
.ceshiwenzishouqi_abs_lt {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: left;
    font-size: 26px;
    color: #666;
    font-weight: 500;
    text-indent: 5px;
}
.ceshiwenzishouqi_abs_rt {
    width: 120px;
    height: 50px;
    line-height: 50px;
    text-align: left;
    font-size: 26px;
    color: #0abbb9;
}
.details {
    min-height: 100vh;
    background-color: #fff;
}
.border {
    height: 16px;
    background-color: #f8f8f8;
}
.my-swipe {
    width: 100%;
    height: 320px;
}
.my-swipe img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.info {
    padding: 30px;
}
.info .factory {
    display: flex;
    align-items: center;
    font-size: 32px;
    color: #1a1a1a;
    font-weight: 700;
}
.info .factory .name {
    font-size: 32px;
    max-width: 72%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.info .factory .tips {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    padding: 0 10px;
    margin-left: 16px;
    background-color: #eeffff;
    color: #0abbb9;
    height: 28px;
    /* line-height: 28px; */
    /* margin-top: 10px; */
}
.info .factory .color50 {
    color: #ec1203;
    background-color: #faeae9;
}
.info .factory .color40 {
    color: #ff6a00;
    background-color: #fcf4ee;
}
.info .factory .color20 {
    color: #999999;
    background-color: #f3f3f3;
}
.info .wage {
    font-weight: 700;
    color: #0abbb9;
    display: flex;
    margin-top: 6px;
    white-space: nowrap;
}
.info .wage > div:first-of-type {
    margin-right: 20px;
}
.info .address {
    color: #999;
    margin-top: 12px;
}
.info .address div {
    font-size: 24px;
}

.member_info {
    display: flex;
    justify-content: space-between;
    padding: 30px;
}
.info_left,
.info_right {
    display: flex;
    text-align: center;
    justify-content: space-between;
    align-items: center;
}
.info_left div {
    text-align: left;
    font-size: 24px;
    color: #999;
}
.info_left img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin-right: 16px;
}
.info_left .team_name {
    font-size: 28px;
    color: #1a1a1a;
    font-weight: 700;
    margin-bottom: 6px;
    /* margin-top: 10px; */
    margin-right: 0.4rem;
}
.info_right img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
}
.info_right p {
    font-size: 20px;
}
.block {
    padding: 30px 0;
}
.block .title {
    border-left: 8px solid #0abbb9;
    padding-left: 22px;
    height: 34px;
    display: flex;
    align-items: center;
    /* line-height: 30px; */
    margin-bottom: 8px;
    font-size: 32px;
    font-weight: 700;
}
.block .content {
    padding: 10px 30px 0;
    line-height: 46px;
    color: #666;
    font-size: 26px;
}
.block .content div {
    font-size: 26px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
pre {
    white-space: pre-wrap;
    font-size: 26px;
}
.iconfont {
    font-size: 44px;
}
.footer > div:first-of-type,
.footer > div:nth-of-type(2) {
    width: 120px;
}
.footer > div:first-of-type {
    margin-left: 30px;
    margin-right: 10px;
}
.footer > div > div {
    font-size: 22px;
}
.icon_select {
    color: #0abbb9;
}

.share_content {
    background-color: #fff;
    position: fixed;
    bottom: 0;
    z-index: 3;
    width: 100%;
}
.share_content .share_top {
    margin-top: 30px;
    font-size: 30px;
    text-align: center;
}
.share_content .share_middle {
    display: flex;
    justify-content: space-around;
    text-align: center;
    margin: 50px 0;
}
.share_content .share_middle img {
    width: 90px;
    height: 90px;
}
.share_content .share_middle div {
    font-size: 26px;
}
.share_content .share_bottom {
    border-top: 1px solid #f8f8f8;
    text-align: center;
    line-height: 90px;
    height: 90px;
}
.share_click {
    z-index: 4;
    position: fixed;
    top: 20px;
    right: 30px;
}
.share_click img {
    width: 600px;
}

.store {
    margin-bottom: 20px;
    display: block !important;
}
.store:last-of-type {
    margin-bottom: 0;
}
.store .store_name {
    font-weight: 700;
}
.store > div:first-of-type {
    display: flex;
    justify-content: space-between;
}
.store .store_address {
    width: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 22px !important;
    color: #999;
}
.store .store_distance {
    font-size: 20px !important;
}
.left_go-1 {
    background-color: rgb(0, 0, 0, 0.3);
    /* background-color: #0abbb9; */
    width: 100px;
    height: 60px;
    padding-right: 30px;
    border-radius: 0 40px 40px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #fff;
    position: fixed;
    left: 0px;
    top: 30px;
    z-index: 2;
    font-size: 20px;
}
.left_go-1 > .iconfont {
    font-size: 20px;
    margin-right: 10px;
}
.icon-dingwei {
    font-size: 26px;
}
.icon-dingwei1 {
    font-size: 24px;
    margin-right: 8px;
}
.is_null {
    display: flex;
    align-items: center;
    height: 70vh;
    justify-content: center;
}
.is_null img {
    width: 400px;
}
.is_null .button {
    height: 70px;
    line-height: 70px;
    width: 390px;
    text-align: center;
    color: #fff;
    background: #0abbb9;
    border-radius: 50px;
    margin: 80px auto 0;
}
.pre {
    display: flex;
}
.pre pre {
    width: calc(100% - 130px);
    text-align: justify;
}
.click_more {
    color: #0abbb9;
    justify-content: flex-end;
}
</style>